exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-calibrations-js": () => import("./../../../src/pages/services/calibrations.js" /* webpackChunkName: "component---src-pages-services-calibrations-js" */),
  "component---src-pages-services-electrical-calibration-js": () => import("./../../../src/pages/services/electrical-calibration.js" /* webpackChunkName: "component---src-pages-services-electrical-calibration-js" */),
  "component---src-pages-services-flow-sensor-calibrations-js": () => import("./../../../src/pages/services/flow-sensor-calibrations.js" /* webpackChunkName: "component---src-pages-services-flow-sensor-calibrations-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-iq-oq-pq-validation-js": () => import("./../../../src/pages/services/iq-oq-pq-validation.js" /* webpackChunkName: "component---src-pages-services-iq-oq-pq-validation-js" */),
  "component---src-pages-services-lab-calibrations-js": () => import("./../../../src/pages/services/lab-calibrations.js" /* webpackChunkName: "component---src-pages-services-lab-calibrations-js" */),
  "component---src-pages-services-pipette-calibration-js": () => import("./../../../src/pages/services/pipette-calibration.js" /* webpackChunkName: "component---src-pages-services-pipette-calibration-js" */),
  "component---src-pages-services-pressure-calibration-js": () => import("./../../../src/pages/services/pressure-calibration.js" /* webpackChunkName: "component---src-pages-services-pressure-calibration-js" */),
  "component---src-pages-services-temperature-calibration-js": () => import("./../../../src/pages/services/temperature-calibration.js" /* webpackChunkName: "component---src-pages-services-temperature-calibration-js" */)
}

